import { DC_Domain_Models_Products_ProductType } from '@monorepo-types/dc';
import { DC_Repositories_Base_Enumerations_BusinessUnit } from '@monorepo-types/dc';
import { DC_Repositories_Base_Enumerations_Label } from '@monorepo-types/dc';
import { RequestModels_Address_EditCorrespondanceAddressRequest } from '@monorepo-types/dc';
import { RequestModels_Customers_CreateLocationRequestModel } from '@monorepo-types/dc';
import { RequestModels_Customers_MER_MerStatusRequestModel } from '@monorepo-types/dc';
import { RequestModels_Customers_Profile_ContactPreferencesMutationNLRequestModel } from '@monorepo-types/dc';
import { RequestModels_Customers_Profile_ContactPreferencesMutationRequestModel } from '@monorepo-types/dc';
import { RequestModels_Customers_Profile_CustomerPatchProfileRequestModelNl } from '@monorepo-types/dc';
import { RequestModels_Customers_Profile_CustomerPutProfileRequestModelBe } from '@monorepo-types/dc';
import { RequestModels_Customers_Profile_CustomerPutProfileRequestModelNl } from '@monorepo-types/dc';
import { RequestModels_Customers_Relocations_RelocationRequestModel } from '@monorepo-types/dc';
import { RequestModels_Customers_Relocations_UpdateRelocationRequestModel } from '@monorepo-types/dc';
import { RequestModels_Customers_Relocations_VerifyRelocationRequestModel } from '@monorepo-types/dc';
import { ResponseDataSystem_Boolean } from '@monorepo-types/dc';
import { ResponseModels_CustomerAccountSummary_CustomerAccountSummary } from '@monorepo-types/dc';
import { ResponseModels_Customers_CreateLocationResponseModel } from '@monorepo-types/dc';
import { ResponseModels_Customers_MER_MerStatusResponseModel } from '@monorepo-types/dc';
import { ResponseModels_Customers_Meter_Meter } from '@monorepo-types/dc';
import { ResponseModels_Customers_Profile_ContactPreferenceResponseModel } from '@monorepo-types/dc';
import { ResponseModels_Customers_Profile_CustomerProfileResponse } from '@monorepo-types/dc';
import { ResponseModels_Customers_Relocations_RelocationIntakeResponse } from '@monorepo-types/dc';
import { ResponseModels_Customers_Relocations_Relocations } from '@monorepo-types/dc';
import { ResponseModels_Customers_Relocations_VerifyRelocationResponse } from '@monorepo-types/dc';

import { request } from '../client';
import type { ApiRequestConfig } from '../client/types';

type GetCustomerProfile = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
};
/**
 * GetCustomerProfile
 * Get the customer profile including any open orders
 * @returns ResponseModels_Customers_Profile_CustomerProfileResponse Success
 */
export function getCustomerProfile(
  { businessUnit, label, customerId }: GetCustomerProfile,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseModels_Customers_Profile_CustomerProfileResponse> {
  return request(
    {
      method: 'GET',
      path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/profile`,
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}

type GetCustomerProfileBe = { label: DC_Repositories_Base_Enumerations_Label; customerId: number };
/**
 * GetCustomerProfileBE
 * Get the customer profile including any open orders
 * @returns ResponseModels_Customers_Profile_CustomerProfileResponse Success
 */
export function getCustomerProfileBe(
  { label, customerId }: GetCustomerProfileBe,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseModels_Customers_Profile_CustomerProfileResponse> {
  return request(
    {
      method: 'GET',
      path: `/dxpweb/be/${label}/customers/${customerId}/profile`,
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}

type PutCustomerProfileBe = {
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  requestBody?: RequestModels_Customers_Profile_CustomerPutProfileRequestModelBe;
};
/**
 * PutCustomerProfileBE
 * Put Customer Profile data for BE
 * @returns any Success
 */
export function putCustomerProfileBe(
  { label, customerId, requestBody }: PutCustomerProfileBe,
  requestConfig: ApiRequestConfig = {},
): Promise<any> {
  return request(
    {
      method: 'PUT',
      path: `/dxpweb/be/${label}/customers/${customerId}/profile`,
      body: requestBody,
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}

type PutCustomerProfileNl = {
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  requestBody?: RequestModels_Customers_Profile_CustomerPutProfileRequestModelNl;
};
/**
 * PutCustomerProfileNL
 * Put Customer Profile data for NL
 * @returns any Success
 */
export function putCustomerProfileNl(
  { label, customerId, requestBody }: PutCustomerProfileNl,
  requestConfig: ApiRequestConfig = {},
): Promise<any> {
  return request(
    {
      method: 'PUT',
      path: `/dxpweb/nl/${label}/customers/${customerId}/profile`,
      body: requestBody,
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}

type PatchCustomerProfileNl = {
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  requestBody?: RequestModels_Customers_Profile_CustomerPatchProfileRequestModelNl;
};
/**
 * PatchCustomerProfileNL
 * Patch Customer Profile data for NL
 * @returns any Success
 */
export function patchCustomerProfileNl(
  { label, customerId, requestBody }: PatchCustomerProfileNl,
  requestConfig: ApiRequestConfig = {},
): Promise<any> {
  return request(
    {
      method: 'PATCH',
      path: `/dxpweb/nl/${label}/customers/${customerId}/profile`,
      body: requestBody,
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}

type EditCorrespondenceAddress = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
  requestBody?: RequestModels_Address_EditCorrespondanceAddressRequest;
};
/**
 * EditCorrespondenceAddress
 * Update the correspondence address
 * @returns any Success
 */
export function editCorrespondenceAddress(
  { businessUnit, label, customerId, accountId, requestBody }: EditCorrespondenceAddress,
  requestConfig: ApiRequestConfig = {},
): Promise<any> {
  return request(
    {
      method: 'PUT',
      path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/accounts/${accountId}/correspondenceaddress`,
      body: requestBody,
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}

type GetContactPreferences = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
};
/**
 * GetContactPreferences
 * Gets the preferences contact
 * @returns ResponseModels_Customers_Profile_ContactPreferenceResponseModel Success
 */
export function getContactPreferences(
  { businessUnit, label, customerId }: GetContactPreferences,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseModels_Customers_Profile_ContactPreferenceResponseModel> {
  return request(
    {
      method: 'GET',
      path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/contactpreferences`,
      errors: { 400: 'Bad Request', 404: 'Not Found' },
    },
    requestConfig,
  );
}

type UpdateContactPreferencesNl = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  requestBody?: RequestModels_Customers_Profile_ContactPreferencesMutationNLRequestModel;
};
/**
 * UpdateContactPreferencesNL
 * Update Contact Preferences (NL)
 * @returns any Success
 */
export function updateContactPreferencesNl(
  { businessUnit, label, customerId, requestBody }: UpdateContactPreferencesNl,
  requestConfig: ApiRequestConfig = {},
): Promise<any> {
  return request(
    {
      method: 'PUT',
      path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/contactpreferences`,
      body: requestBody,
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}

type UpdateContactPreferences = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
  requestBody?: RequestModels_Customers_Profile_ContactPreferencesMutationRequestModel;
};
/**
 * UpdateContactPreferences
 * Update Contact Preferences (MarketingEnabled, ThirdPartyMarketingEnabled) (BE)
 * @returns any Success
 */
export function updateContactPreferences(
  { businessUnit, label, customerId, accountId, requestBody }: UpdateContactPreferences,
  requestConfig: ApiRequestConfig = {},
): Promise<any> {
  return request(
    {
      method: 'PUT',
      path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/accounts/${accountId}/contactpreferences`,
      body: requestBody,
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}

type GetMerStatusForCustomerAccount = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
};
/**
 * GetMerStatusForCustomerAccount
 * Gets the mer status (MER is active and contact preference MerByEmail is true)
 * @returns ResponseModels_Customers_MER_MerStatusResponseModel Success
 */
export function getMerStatusForCustomerAccount(
  { businessUnit, label, customerId, accountId }: GetMerStatusForCustomerAccount,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseModels_Customers_MER_MerStatusResponseModel> {
  return request(
    {
      method: 'GET',
      path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/accounts/${accountId}/mer/status`,
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}

type UpdateMerStatusForCustomerAccount = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
  requestBody: RequestModels_Customers_MER_MerStatusRequestModel;
};
/**
 * UpdateMerStatusForCustomerAccount
 *
 * @returns any Success
 */
export function updateMerStatusForCustomerAccount(
  { businessUnit, label, customerId, accountId, requestBody }: UpdateMerStatusForCustomerAccount,
  requestConfig: ApiRequestConfig = {},
): Promise<any> {
  return request(
    {
      method: 'PATCH',
      path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/accounts/${accountId}/mer/status`,
      body: requestBody,
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}

type DownloadGdprData = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
};
/**
 * DownloadGdprData
 * Downloads the GDPR data from customerId
 * @returns Blob Success
 */
export function downloadGdprData(
  { businessUnit, label, customerId }: DownloadGdprData,
  requestConfig: ApiRequestConfig = {},
): Promise<Blob> {
  return request(
    {
      method: 'GET',
      path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/gdprdata/download`,
      errors: { 400: 'Bad Request', 404: 'Not Found' },
    },
    requestConfig,
  );
}

type GetRelocationIntake = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
};
/**
 * GetRelocationIntake
 * Check if this account is eligible for a relocation
 * @returns ResponseModels_Customers_Relocations_RelocationIntakeResponse Success
 */
export function getRelocationIntake(
  { businessUnit, label, customerId, accountId }: GetRelocationIntake,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseModels_Customers_Relocations_RelocationIntakeResponse> {
  return request(
    {
      method: 'GET',
      path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/accounts/${accountId}/relocations/intake`,
      errors: { 400: 'Bad Request', 404: 'Not Found' },
    },
    requestConfig,
  );
}

type VerifyRelocationRequest = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
  requestBody?: RequestModels_Customers_Relocations_VerifyRelocationRequestModel;
};
/**
 * VerifyRelocationRequest
 * Verify if a customer can move to a requested address before pushing the actual request
 * @returns ResponseModels_Customers_Relocations_VerifyRelocationResponse Success
 */
export function verifyRelocationRequest(
  { businessUnit, label, customerId, accountId, requestBody }: VerifyRelocationRequest,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseModels_Customers_Relocations_VerifyRelocationResponse> {
  return request(
    {
      method: 'POST',
      path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/accounts/${accountId}/relocations/verify`,
      body: requestBody,
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}

type UpdateRelocationDate = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  relocationId: number;
  requestBody?: RequestModels_Customers_Relocations_UpdateRelocationRequestModel;
};
/**
 * UpdateRelocationDate
 * Updates the requestdate of a relocation
 * @returns ResponseDataSystem_Boolean Success
 */
export function updateRelocationDate(
  { businessUnit, label, customerId, relocationId, requestBody }: UpdateRelocationDate,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseDataSystem_Boolean> {
  return request(
    {
      method: 'PATCH',
      path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/relocations/${relocationId}`,
      body: requestBody,
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}

type GetRelocations = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
};
/**
 * GetRelocations
 * Get customer relocations
 * @returns ResponseModels_Customers_Relocations_Relocations Success
 */
export function getRelocations(
  { businessUnit, label, customerId }: GetRelocations,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseModels_Customers_Relocations_Relocations> {
  return request(
    {
      method: 'GET',
      path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/relocations`,
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}

type PutRelocation = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
  requestBody?: RequestModels_Customers_Relocations_RelocationRequestModel;
};
/**
 * PutRelocation
 * Create relocation for account
 * @returns void
 */
export function putRelocation(
  { businessUnit, label, customerId, accountId, requestBody }: PutRelocation,
  requestConfig: ApiRequestConfig = {},
): Promise<void> {
  return request(
    {
      method: 'PUT',
      path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/accounts/${accountId}/relocations`,
      body: requestBody,
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}

type CreateLocation = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  requestBody: RequestModels_Customers_CreateLocationRequestModel;
};
/**
 * CreateLocation
 * Associates a location to the current user in Enode's system
 * @returns ResponseModels_Customers_CreateLocationResponseModel Success
 */
export function createLocation(
  { businessUnit, label, customerId, requestBody }: CreateLocation,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseModels_Customers_CreateLocationResponseModel> {
  return request(
    {
      method: 'PUT',
      path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/locations`,
      body: requestBody,
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}

type GetCustomerAccountSummary = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
};
/**
 * GetCustomerAccountSummary
 * Get Customer-Account combined information about a customer for a specific account.
Used for contract renewal. Can be used for other flows (move in/out for example) too in the future.
 * @returns ResponseModels_CustomerAccountSummary_CustomerAccountSummary OK with customer profile wrapped in a data object.
 */
export function getCustomerAccountSummary(
  { businessUnit, label, customerId, accountId }: GetCustomerAccountSummary,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseModels_CustomerAccountSummary_CustomerAccountSummary> {
  return request(
    {
      method: 'GET',
      path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/accounts/${accountId}/summary`,
      errors: { 400: 'Validation is incorrect or backend returns a functional error.' },
    },
    requestConfig,
  );
}

type GetMetersByAccount = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  customerId: number;
  accountId: number;
  productType?: DC_Domain_Models_Products_ProductType;
};
/**
 * GetMetersByAccount
 * Get customer meters
 * @returns ResponseModels_Customers_Meter_Meter Success
 */
export function getMetersByAccount(
  { businessUnit, label, customerId, accountId, productType }: GetMetersByAccount,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseModels_Customers_Meter_Meter> {
  return request(
    {
      method: 'GET',
      path: `/dxpweb/${businessUnit}/${label}/customers/${customerId}/accounts/${accountId}/meters`,
      query: { productType },
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}
