import { createDCHook, createMutationHook, collapseDataFromCall, collapseParams } from '../client';
import {
  getCustomerProfile,
  getCustomerProfileBe,
  putCustomerProfileBe,
  putCustomerProfileNl,
  patchCustomerProfileNl,
  editCorrespondenceAddress,
  getContactPreferences,
  updateContactPreferencesNl,
  updateContactPreferences,
  getMerStatusForCustomerAccount,
  updateMerStatusForCustomerAccount,
  downloadGdprData,
  getRelocationIntake,
  verifyRelocationRequest,
  updateRelocationDate,
  getRelocations,
  putRelocation,
  createLocation,
  getCustomerAccountSummary,
  getMetersByAccount,
} from '../services/CustomerService';

export const useCustomerGetCustomerProfile = createDCHook(
  'getCustomerProfile',
  collapseDataFromCall(getCustomerProfile),
  { injectables: ['label', 'customerId', 'businessUnit'], flattenData: true },
  { maxAge: 30000 },
);

export const useCustomerGetCustomerProfileBe = createDCHook(
  'getCustomerProfileBe',
  collapseDataFromCall(getCustomerProfileBe),
  { injectables: ['label', 'customerId'], flattenData: true },
);

export const useCustomerPutCustomerProfileBe = createMutationHook(
  collapseParams(
    collapseParams(putCustomerProfileBe, 'requestBody', 'data'),
    'data',
    'phoneNumber',
    'mobilePhoneNumber',
    'email',
    'dateOfBirth',
    'gender',
  ),
  {
    injectables: ['label', 'customerId'],
    flattenData: false,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      { target: 'data', props: ['phoneNumber', 'mobilePhoneNumber', 'email', 'dateOfBirth', 'gender'] },
    ],
  },
);

export const useCustomerPutCustomerProfileNl = createMutationHook(
  collapseParams(
    collapseParams(putCustomerProfileNl, 'requestBody', 'data'),
    'data',
    'phoneNumber',
    'mobilePhoneNumber',
    'firstName',
    'email',
    'gender',
  ),
  {
    injectables: ['label', 'customerId'],
    flattenData: false,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      { target: 'data', props: ['phoneNumber', 'mobilePhoneNumber', 'firstName', 'email', 'gender'] },
    ],
  },
);

export const useCustomerPatchCustomerProfileNl = createMutationHook(
  collapseParams(
    collapseParams(patchCustomerProfileNl, 'requestBody', 'data'),
    'data',
    'phoneNumber',
    'mobilePhoneNumber',
    'email',
    'address',
    'firstName',
    'gender',
    'sendEmail',
  ),
  {
    injectables: ['label', 'customerId'],
    flattenData: false,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      {
        target: 'data',
        props: ['phoneNumber', 'mobilePhoneNumber', 'email', 'address', 'firstName', 'gender', 'sendEmail'],
      },
    ],
  },
);

export const useCustomerEditCorrespondenceAddress = createMutationHook(
  collapseParams(
    collapseParams(editCorrespondenceAddress, 'requestBody', 'data'),
    'data',
    'postalCode',
    'houseNumber',
    'houseNumberSuffix',
    'street',
    'city',
    'bus',
  ),
  {
    injectables: ['label', 'customerId', 'accountId', 'businessUnit'],
    flattenData: false,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      { target: 'data', props: ['postalCode', 'houseNumber', 'houseNumberSuffix', 'street', 'city', 'bus'] },
    ],
  },
);

export const useCustomerGetContactPreferences = createDCHook(
  'getContactPreferences',
  collapseDataFromCall(getContactPreferences),
  { injectables: ['label', 'customerId', 'businessUnit'], flattenData: true },
  { maxAge: 15000 },
);

export const useCustomerUpdateContactPreferencesNl = createMutationHook(updateContactPreferencesNl, {
  injectables: ['label', 'customerId', 'businessUnit'],
  flattenData: false,
});

export const useCustomerUpdateContactPreferences = createMutationHook(
  collapseParams(
    collapseParams(updateContactPreferences, 'requestBody', 'data'),
    'data',
    'marketingEnabled',
    'thirdPartyMarketingEnabled',
  ),
  {
    injectables: ['label', 'customerId', 'accountId', 'businessUnit'],
    flattenData: false,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      { target: 'data', props: ['marketingEnabled', 'thirdPartyMarketingEnabled'] },
    ],
  },
);

export const useCustomerGetMerStatusForCustomerAccount = createDCHook(
  'getMerStatusForCustomerAccount',
  collapseDataFromCall(getMerStatusForCustomerAccount),
  { injectables: ['label', 'customerId', 'accountId', 'businessUnit'], flattenData: true },
);

export const useCustomerUpdateMerStatusForCustomerAccount = createMutationHook(
  collapseParams(
    collapseParams(updateMerStatusForCustomerAccount, 'requestBody', 'data'),
    'data',
    'merIsActive',
    'contactByEmail',
  ),
  {
    injectables: ['label', 'customerId', 'accountId', 'businessUnit'],
    flattenData: false,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      { target: 'data', props: ['merIsActive', 'contactByEmail'] },
    ],
  },
);

export const useCustomerDownloadGdprData = createDCHook('downloadGdprData', downloadGdprData, {
  injectables: ['label', 'customerId', 'businessUnit'],
  flattenData: false,
});

export const useCustomerGetRelocationIntake = createDCHook(
  'getRelocationIntake',
  collapseDataFromCall(getRelocationIntake),
  { injectables: ['label', 'customerId', 'accountId', 'businessUnit'], flattenData: true },
);

export const useCustomerVerifyRelocationRequest = createMutationHook(
  collapseDataFromCall(
    collapseParams(
      collapseParams(verifyRelocationRequest, 'requestBody', 'data'),
      'data',
      'zipCode',
      'houseNumber',
      'houseNumberSuffix',
      'city',
      'street',
    ),
  ),
  {
    injectables: ['label', 'customerId', 'accountId', 'businessUnit'],
    flattenData: true,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      { target: 'data', props: ['zipCode', 'houseNumber', 'houseNumberSuffix', 'city', 'street'] },
    ],
  },
);

export const useCustomerUpdateRelocationDate = createMutationHook(
  collapseDataFromCall(collapseParams(collapseParams(updateRelocationDate, 'requestBody', 'data'), 'data', 'date')),
  {
    injectables: ['label', 'customerId', 'businessUnit'],
    flattenData: true,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      { target: 'data', props: ['date'] },
    ],
  },
);

export const useCustomerGetRelocations = createDCHook(
  'getRelocations',
  collapseDataFromCall(getRelocations),
  { injectables: ['label', 'customerId', 'businessUnit'], flattenData: true },
  { maxAge: 15000 },
);

export const useCustomerPutRelocation = createMutationHook(
  collapseParams(
    collapseParams(putRelocation, 'requestBody', 'data'),
    'data',
    'keyDateCurrentAddress',
    'newAddress',
    'keyDateNewAddress',
    'isResidence',
  ),
  {
    injectables: ['label', 'customerId', 'accountId', 'businessUnit'],
    flattenData: false,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      { target: 'data', props: ['keyDateCurrentAddress', 'newAddress', 'keyDateNewAddress', 'isResidence'] },
    ],
  },
);

export const useCustomerCreateLocation = createMutationHook(
  collapseDataFromCall(
    collapseParams(collapseParams(createLocation, 'requestBody', 'data'), 'data', 'name', 'latitude', 'longitude'),
  ),
  {
    injectables: ['label', 'customerId', 'businessUnit'],
    flattenData: true,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      { target: 'data', props: ['name', 'latitude', 'longitude'] },
    ],
  },
);

export const useCustomerGetCustomerAccountSummary = createDCHook(
  'getCustomerAccountSummary',
  collapseDataFromCall(getCustomerAccountSummary),
  { injectables: ['label', 'customerId', 'accountId', 'businessUnit'], flattenData: true },
);

export const useCustomerGetMetersByAccount = createDCHook(
  'getMetersByAccount',
  collapseDataFromCall(getMetersByAccount),
  { injectables: ['label', 'customerId', 'accountId', 'businessUnit'], flattenData: true },
);
